<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-navigation-drawer :mini-variant="mini" app v-model="drawer" :permanent="$vuetify.breakpoint.mdAndUp">
            <span v-if="smallImage">
                <v-list-item style="height: 92px">
                <img src="../../../../assets/gfi.png" style="width: 100%; height: auto "/>
            </v-list-item>
            </span>
            <span v-if="!smallImage">
               <v-list-item style="height: 92px">
                <img src="../../../../assets/gfi.png" style="width: 70%; height: auto "/>
            </v-list-item>
            </span>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item-group color="primary">
                    <router-link to="/dashboard">
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon small>mdi-chart-areaspline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('msg.dashboard') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <router-link :to="`/product/${product.toLowerCase()}`"
                                 v-for="(product, idx) in $store.state.home.user.products" :key="idx">
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon small>{{ $t(`icon.${product.toUpperCase()}`)}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content v-if="$store.state.home.user.products.length > 1">
                                <v-list-item-title>{{ product }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content v-else>
                                <v-list-item-title>{{ $t('msg.results') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <router-link v-show="showExportOption" to="/export-history">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-history</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('msg.export_history') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </router-link>
<!--                    <router-link to="/learningResources">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-cogs</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('msg.learning_resources') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </router-link>-->
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import {EventBus} from '../../../../_helpers'

    export default {
        name: 'SideBar',
        data() {
            return {
                drawer: false,
                mini: true,
                fav: true,
                menu: false,
                item: '',
                message: false,
                hints: true,
                smallImage: true
            }
        },
        methods: {
            handleSideBar: function () {
                this.mini = !this.mini
                this.smallImage = !this.smallImage
                this.drawer = !this.drawer
            },
            handleSideBarMobile: function () {
                this.drawer = true
                this.mini = false
            },
            handleSideBarTab: function () {
                this.mini = !this.mini
                this.smallImage = !this.smallImage
                this.drawer = !this.drawer
            },
        },
        computed: {
            isUserAuthenticated() {
                return this.$store.state.home.authenticated
            },
            showExportOption: function () {
               return (this.$store.state.home.user.roles.some(role => ['FI_OFFICER','ROLE_HQ_OFFICER'].includes(role)) && this.$store.state.home.user.isExportButtonEnabled)
            },
            showProfileOption: function () {
                return this.$store.state.home.user.enableProfile
            }
        },
        created() {
            EventBus.$on('Clicked', this.handleSideBar)
            EventBus.$on('Sidebar', this.handleSideBarMobile)
            EventBus.$on('SidebarForTab', this.handleSideBarTab)
        }
    }
</script>
<style scoped>
    .v-application a {
        text-decoration: none;
    }
</style>
